<template>
    <div>
        <!-- <h2>{{ $t("economicSector.title") }}</h2> -->

        <v-btn   color="primary" class="ma-2 white--text"  @click="addSectorDialog = true">
            {{ $t("economicSector.add") }} <v-icon right dark> mdi-plus</v-icon>
        </v-btn>

         <!-- <b-button variant="outline-primary" v-b-modal.AddEconomicSecotor>
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            {{ $t("economicSector.add") }}
        </b-button> -->

        <v-container color="primary">
              <v-card
                    icon="clipboard-text"
                    title="Economic Sector"
                    class="px-5 py-10">

                     <v-card-title>
                    <v-row>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details>
                        </v-text-field>
                    </v-row>
                    <v-row>
                        <v-progress-linear
                            :active="loading"
                            color="green"
                            :indeterminate="loading"
                            absolute
                            bottom
                            height="15">
                            Loading...
                        </v-progress-linear>`
                    </v-row>
                </v-card-title>

                      <v-data-table
                        dense
                        :headers="fields"
                        :items="items"
                        :search="search"
                        class="elevation-1">
                            <template  v-slot:[`header.englishName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                            <template  v-slot:[`header.arabicName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                             <template  v-slot:[`header.Action`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                              <template v-slot:top>
                                  <v-dialog
                                    v-model="Editdialog"
                                    max-width="800px">
                                        <v-card>
                                             <v-card-title>
                                                <span class="text-h5">Edit Economic Sector</span>
                                            </v-card-title>
                                            <v-card-text>
                                                 <v-form ref="form" v-model="valid" lazy-validation>
                                                    <v-container>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.englishName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="English Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.arabicName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="Arabic Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                    </v-container>
                                                 </v-form>
                                            </v-card-text>
                                             <v-card-actions>
                                                <v-spacer></v-spacer>
                                                 <v-btn color="blue darken-1" text @click="close"> Cancel</v-btn>
                                                 <v-btn color="blue darken-1" :disabled="!valid" text @click="validate" >Save</v-btn>
                                             </v-card-actions>
                                        </v-card>
                                  </v-dialog>
                              </template>
                               <template v-slot:[`item.Action`]="{ item }">
                                    <v-icon small class="mr-2"  @click="editItem(item)" >
                                        mdi-pencil
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="red" v-if="item.status==0" >
                                        mdi-brightness-1
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="green" v-if="item.status==1" >
                                        mdi-brightness-1
                                    </v-icon>
                                    <v-icon small class="mr-2"  @click="approveDeleteConfirm(item)" color="red"  >
                                        mdi-delete-empty
                                    </v-icon>
                                </template>

                      </v-data-table>
              </v-card>



        <v-dialog
        v-model="addSectorDialog"
        max-width="800px">
            <v-card>
                    <v-card-title>
                    <span class="text-h5">Add Economic Sector</span>
                </v-card-title>
                <v-card-text>
                        <v-form ref="Addform" v-model="valid" lazy-validation>
                        <v-container>
                                <v-row>
                                <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                        v-model="editedItem.englishName"
                                        :rules="[rules.required]"
                                        required
                                        label="English Name">
                                    </v-text-field>
                                </v-col>
                                </v-row>
                                <v-row>
                                <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                        v-model="editedItem.arabicName"
                                        :rules="[rules.required]"
                                        required
                                        label="Arabic Name">
                                    </v-text-field>
                                </v-col>
                                </v-row>
                        </v-container>
                        </v-form>
                </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close"> Cancel</v-btn>
                        <v-btn color="blue darken-1" :disabled="!valid" text @click="validateNew" >Save</v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>


        <div class="text-center">
                <v-snackbar v-model="snackbar" timeout="3000">
                    {{ alertMsg }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
            </v-snackbar>
        </div>

         <v-dialog v-model="existingIndustryType" max-width="500px">
          <v-card>
            <v-card-title class="text-h3">Can't update status, economic sector has Industry types, please deactivate them first and then try again  </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="existingIndustryType = false">Close</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

         <v-dialog v-model="approveReqDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="updateSectorStatus">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="closeApprove">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>


                <v-dialog v-model="confirmDeleteDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteSector">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="confirmDeleteDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>


                 <!-- <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="danger"
                            v-bind="attrs"
                            v-on="on">Cannot delete Economic Sector</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark>Opening from the bottom</v-toolbar>
                            <v-card-text>
                            <div class="text-h2 pa-12">Hello world!</div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            </v-card-actions>
                        </v-card>
                        </template>
                </v-dialog> -->



    </v-container>





    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
     watch: {
      Editdialog (val) {
        val || this.close()
      },
    },
    data(){
        return{
            search:'',
            loading:false,
            approveReqDialog:false,
            existingIndustryType:false,
            Editdialog: false,
            addSectorDialog:false,
            confirmDeleteDialog:false,
            valid: false,
            editedItem: {
                id:-1,
                arabicName: '',
                englishName:'',
                status:-1,
            },
            editedIndex:-1,
            rules: {
                required: value => !!value || 'Required Field',
            },

            fields: [
                // {value: 'id', text: this.$t("economicSector.grid.id") },
                {value: 'englishName', text: this.$t("economicSector.grid.englishName") },
                {value: 'arabicName', text: this.$t("economicSector.grid.arabicName") },
                {value: 'Action', text: this.$t("economicSector.grid.Action") }],
            items: [],
           form: {
                englishName: null,
                arabicName:null,
                id:null
            },
            alertMsg:'',
            snackbar:false,
            currentEditedSector:null
        }
    },
    validations: {
        form: {
            englishName: {
                required,
                minLength: minLength(3)
            },
            arabicName: {
                required,
                minLength: minLength(3)
            }
        }
    },
     methods:{
         editItem (item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.Editdialog = true
        },
         approveRequestConfirm(item){
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)

            this.approveReqDialog = true;
        },
        approveDeleteConfirm(item){
            if(Boolean(item.hasChildren) == false){
                 this.editedItem = item;
                this.editedIndex = this.items.indexOf(item)
                this.confirmDeleteDialog = true
            }
            else{
                this.alertMsg = "Economic Sector has Industry types cannot be deleted"
                this.snackbar = true;
            }

            // this.editedItem = item;
            // this.editedIndex = this.items.indexOf(item)

            // this.approveDeleteDialog = true;
        },
         closeApprove(){
            this.approveReqDialog = false;
            this.clear();
        },
         validate () {
            if(this.$refs.form.validate())
            {
                this.updateEconomicSector();
            }
         },
         validateNew () {
            if(this.$refs.Addform.validate())
            {
                this.AddEconomicSector();
            }
         },
          close () {
            this.Editdialog = false
            this.addSectorDialog = false;
            this.$nextTick(() => {
                this.clear();
            })
        },
        clear(){
                this.editedIndex = -1
                this.editedItem.id = -1;
                this.editedItem.arabicName = '';
                this.editedItem.englishName = '';
        },
        AddEconomicSector(){
             this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/EconomicSector`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName
                    }
                }).then((response)=>{

                    if(JSON.parse(response.data.status) == true)
                    {
                            this.$nextTick(() => {
                                this.items = response.data.result;
                                this.close()
                                this.alertMsg = "Economic Sector added successfully"
                                this.snackbar = true;
                            });
                    }

                });


        },
        updateEconomicSector(){
              this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/EconomicSector`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName,
                        id:this.editedItem.id
                    }
                }).then((response)=>{
                    if(response.data == true)
                    {
                        Object.assign(this.items[this.editedIndex], this.editedItem)
                        this.close()
                        this.alertMsg = "Economic Sector updated successfully"
                        this.snackbar = true;
                    }
                });
         },
         updateSectorStatus(){
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/EconomicSector/status`,
                    params:{
                        Sector:this.editedItem.id,
                        status:this.editedItem.status
                    }
                }).then((response)=>{
                    this.approveReqDialog = false;
                    if(JSON.parse(response.data.status) == true){
                        if(JSON.parse(response.data.result) == true) {
                             this.$nextTick(() => {
                                    this.items[this.editedIndex].status == 1 ?
                                    this.items[this.editedIndex].status = 0: this.items[this.editedIndex].status = 1
                                    this.alertMsg = "Economic Sector updated successfully"
                                    this.snackbar = true;
                             })
                        }
                        else{
                            this.existingIndustryType = true;
                        }
                    }

                });


            // if(item.status == 1){
            //     this.$bvModal.msgBoxOk('Sector is already Active', {
            //             title: 'Confirmation',
            //             size: 'sm',
            //             buttonSize: 'sm',
            //             okVariant: 'success',
            //             headerClass: 'p-2 border-bottom-0',
            //             footerClass: 'p-2 border-top-0',
            //             centered: true
            //             })
            //             return;
            // }
            // if(activate == 0 && item.status == 0){
            //     this.$bvModal.msgBoxOk('Sector is already InActive', {
            //             title: 'Confirmation',
            //             size: 'sm',
            //             buttonSize: 'sm',
            //             okVariant: 'success',
            //             headerClass: 'p-2 border-bottom-0',
            //             footerClass: 'p-2 border-top-0',
            //             centered: true
            //             })
            //             return;
            // }
            // this.$axios({
            //         method: 'put',
            //         url: `${this.$baseURL}/EconomicSector/status?Sector=${item.id}&status=${activate}`,
            //     }).then(()=>{
            //             this.items[index].status = activate;
            //             this.$refs.SectorsList.refresh();
            //     });

        },
        getVariant(item,activateSector){
            if(Boolean(activateSector) == true){
                if(item.status == 1)
                    return 'primary'
                else
                    return 'secondary'
            }
            else{
                if(item.status == 1)
                    return 'secondary'
                else
                    return 'primary'
            }

        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        editSector(item,index)
        {
            this.form.englishName = item.englishName;
            this.form.arabicName = item.arabicName;
            this.form.id = item.id;
            this.$bvModal.show('EditEconomicSecotor');
            this.currentEditedSector = index;

        },
        deleteSector(){
                this.$axios({
                    method: 'delete',
                    url: `${this.$baseURL}/EconomicSector`,
                    data: {Id:this.editedItem.id}
                    }).then(()=>{
                         this.items .splice(this.editedIndex , 1);
                        this.confirmDeleteDialog = false;
                    });
        },
        onReset(){
             this.form ={
                englishName: null,
                arabicName:null
            };
             this.$nextTick(() => {
                this.$v.$reset();
            });
        },

    },
    mounted(){
        this.loading  = true;
        this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/EconomicSector`,
                    data: {
                        EnglishName: this.form.englishName,
                        ArabicName: this.form.arabicName
                    }
                })
                .then((response)=>{
                    console.log('response')
                    console.log(response)
                    this.items = response.data.result;
                    this.loading = false;
                });
    }
}
</script>

<style>

</style>